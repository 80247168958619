// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import 'bootstrap';

Turbolinks.start()
ActiveStorage.start()
require('jquery')
require('@popperjs/core')
import "@fortawesome/fontawesome-free/js/all";


// TODO: Split this file into separate JS files
// For Home Page
const mobileBtn = document.getElementById('mobile-cta');
const runScriptBtn = document.getElementById('run-script');
const scriptResponse = document.getElementById('script-response');
const nav = document.querySelector('nav');
const mobileBtnExit = document.getElementById('mobile-exit');

mobileBtn.addEventListener('click', () => {
    nav.classList.add('menu-btn');
});

mobileBtnExit.addEventListener('click', () => {
    nav.classList.remove('menu-btn');
});

if (runScriptBtn) {
    runScriptBtn.addEventListener('click', () => {
        setTimeout(() => {
            scriptResponse.classList.add('display');
        }, 1000)
    });
}

// For Guide Page
const authorXMLSwitch = document.getElementById('author-switch')
const articleXMLSwitch = document.getElementById('article-switch')
const commentXMLSwitch = document.getElementById('comment-switch')

if (authorXMLSwitch) {
    authorXMLSwitch.addEventListener('change', (event) => {
        if (event.target.checked) {
            var divs = document.querySelectorAll('.xml-link-author');
            for (var i = 0; i < divs.length; i++) {
                console.log("LIST");
                divs[i].classList.add('xml-enabled');
            }
        } else {
            var divs = document.querySelectorAll('.xml-link-author');
            for (var i = 0; i < divs.length; i++) {
                divs[i].classList.remove('xml-enabled');
            }
        }
    })

    articleXMLSwitch.addEventListener('change', (event) => {
        if (event.target.checked) {
            var divs = document.querySelectorAll('.xml-link-article');
            for (var i = 0; i < divs.length; i++) {
                console.log("LIST");
                divs[i].classList.add('xml-enabled');
            }
        } else {
            var divs = document.querySelectorAll('.xml-link-article');
            for (var i = 0; i < divs.length; i++) {
                divs[i].classList.remove('xml-enabled');
            }
        }
    })

    commentXMLSwitch.addEventListener('change', (event) => {
        if (event.target.checked) {
            var divs = document.querySelectorAll('.xml-link-comment');
            for (var i = 0; i < divs.length; i++) {
                console.log("LIST");
                divs[i].classList.add('xml-enabled');
            }
        } else {
            var divs = document.querySelectorAll('.xml-link-comment');
            for (var i = 0; i < divs.length; i++) {
                divs[i].classList.remove('xml-enabled');
            }
        }
    });

}


const sideBarButton = document.getElementById('sidebarCollapse');

if (sideBarButton) {
    sideBarButton.addEventListener('click', () => {
        $('#sidebar').toggleClass('active');
    });
}

